import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { Text, Button, Error, Success } from "../../controls";
import { useStore } from "../../store";
import PaperService from "../../services/paper";
import { useNavigate, useParams } from "react-router-dom";
import { CloudUpload } from "@mui/icons-material";
import moment from "moment";
import SubmissionService from "../../services/submission";

function Upload() {
  const navigate = useNavigate();
  let { paperId } = useParams();
  const { setIsLoading } = useStore((state) => state);
  let [registrationNumber, setRegistrationNumber] = useState("");
  let [files, setFiles] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [paper, setPaper] = useState(null);

  useEffect(() => {
    if (!paperId) return;
    reload();
  }, [paperId]);

  const reload = () => {
    setIsLoading(true);
    PaperService.getById(paperId).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      setPaper(result.data);
    });
  };

  const handleSubmit = async () => {
    if (!paperId) return;
    if (!registrationNumber) {
      setErrorMessage("Registration number is required.");
      return;
    }

    let fd = new FormData();
    fd.append("paperId", paperId);
    fd.append("registrationNumber", registrationNumber);
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      fd.append(file?.name, file);
    }

    setIsLoading(true);
    let respUpload = await SubmissionService.upload(fd);
    if (respUpload.error) {
      setErrorMessage(respUpload.error);
      setIsLoading(false);
      return;
    }

    let respSubmit = await SubmissionService.submit({
      paperId,
      registrationNumber,
    });
    if (respSubmit.error) {
      setErrorMessage(respSubmit.error);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setSuccessMessage("Your paper is submitted successfully!");
    setTimeout(() => {
      navigate("/success");
    }, 1000);
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Typography component="div" variant="h4">
          Submit
        </Typography>
        <Typography component="div" variant="h6">
          {paper?.title}
        </Typography>
        <Typography component="span" variant="h6" color="grey" fontSize="small">
          {paper?.userId?.name},{" "}
          {moment(paper?.createdAt).format("DD MMMM YYYY")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component="span" variant="div">
          Upload your documents for this assignment.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Text
          sx={{ maxWidth: 400 }}
          label="Registration Number"
          value={registrationNumber}
          onChange={setRegistrationNumber}
          style={{ marginTop: 0, marginBottom: 0 }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          type="file"
          onChange={(e) => setFiles(e.target.files)}
          fullWidth
          color="primary"
          sx={{ maxWidth: 400 }}
          inputProps={{
            multiple: true,
            accept: "image/jpg, image/jpeg, image/png, application/pdf",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          text="Upload & Submit"
          onClick={handleSubmit}
          sx={{ maxWidth: 250 }}
          icon={<CloudUpload />}
        />
      </Grid>

      <Grid item xs={12}>
        <Error text={errorMessage} />
        <Success text={successMessage} />
      </Grid>
    </Grid>
  );
}

export default Upload;
