import React, { useState } from "react";
import UserService from "../../services/user";
import { useStore, usePersistStore } from "../../store";
import { Button, Error, Success, Heading, LinkButton } from "../../controls";
import { Verified } from "@mui/icons-material";
import { Container, Box } from "@mui/material";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";

function Verify() {
  const navigate = useNavigate();
  const {
    emailAddress,
    setEmailAddress,
    verificationPurpose,
    setVerificationPurpose,
  } = usePersistStore((state) => state);
  const { setIsLoading } = useStore((state) => state);
  const [pin, setPin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!pin || pin.length === 0) {
      setErrorMessage(`OTP is required.`);
      return;
    }

    setIsLoading(true);
    let vp = verificationPurpose;
    UserService.verifyPin({
      email: emailAddress,
      otp: pin,
      verificationPurpose: vp,
    }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      setSuccessMessage(
        vp === "signup"
          ? `OTP verified successfully! Your account has been activated, please login to get started.`
          : "OTP verified successfully! You can now create your new password."
      );
      setTimeout(() => {
        setVerificationPurpose("");
        if (vp === "rp") navigate("/rp");
        if (vp === "signup") navigate("/");
      }, 1000);
    });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Heading text="OTP Verification" sx={{ mt: 10, mb: 3 }} />
      <>Enter the OTP we sent to: {emailAddress}</>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 3,
        }}
      >
        <PinInput
          length={6}
          initialValue=""
          onChange={(value) => setPin(value)}
          type="numeric"
          inputMode="number"
          style={{ padding: "10px" }}
          inputStyle={{
            borderColor: "#aeaeae",
            borderRadius: 12,
            maxWidth: 70,
            width: 70,
            height: 70,
            margin: "0px 8px",
            fontSize: 24,
            fontWeight: 700,
          }}
          inputFocusStyle={{
            borderColor: "#000",
          }}
          onComplete={() => {}}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Box>
      <Button
        text="Verify"
        onClick={handleSubmit}
        sx={{ mt: 2, mb: 2 }}
        icon={<Verified />}
      />
      <LinkButton
        text="Cancel and go back"
        to="/login"
        onClick={() => setEmailAddress("")}
      />
      <Error text={errorMessage} />
      <Success text={successMessage} />
    </Container>
  );
}

export default Verify;
