import "./styles/style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import { useStore, usePersistStore } from "./store";
import Loading from "./components/Loading";
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";
import ForgetPassword from "./pages/auth/ForgetPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Activate from "./pages/auth/Activate";
import Profile from "./pages/user/Profile";
import Verify from "./pages/auth/Verify";
import Header from "./components/Header";
import ToastError from "./components/ToastError";
import ToastSuccess from "./components/ToastSuccess";
import ToastInfo from "./components/ToastInfo";
import Create from "./pages/home/Create";
import Details from "./pages/home/Details";
import Upload from "./pages/home/Upload";
import SubmitSuccess from "./pages/home/SubmitSuccess";

function App() {
  const {
    isLoading,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
    infoMessage,
    setInfoMessage,
  } = useStore((s) => s);
  const { token, user } = usePersistStore((s) => s);
  return (
    <Router>
      {isLoading === true && <Loading />}
      <ToastError
        open={errorMessage?.length > 0}
        onClose={() => setErrorMessage("")}
        body={errorMessage}
      />
      <ToastSuccess
        open={successMessage?.length > 0}
        onClose={() => setSuccessMessage("")}
        body={successMessage}
      />
      <ToastInfo
        open={infoMessage?.length > 0}
        onClose={() => setInfoMessage("")}
        body={infoMessage}
      />
      {token && user ? (
        <>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/new" element={<Create />} />
            <Route exact path="/paper/:paperId" element={<Details />} />
            <Route exact path="/submit/:paperId" element={<Upload />} />
            <Route exact path="/success" element={<SubmitSuccess />} />
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/fp" element={<ForgetPassword />} />
            <Route exact path="/rp" element={<ResetPassword />} />
            <Route exact path="/activate" element={<Activate />} />
            <Route exact path="/verify" element={<Verify />} />
            <Route exact path="/" element={<Login />} />
            <Route exact path="/submit/:paperId" element={<Upload />} />
            <Route exact path="/success" element={<SubmitSuccess />} />
          </Routes>
        </>
      )}
    </Router>
  );
}

export default App;
