import React, { useEffect, useState } from "react";
import { useStore, usePersistStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Box, TextField } from "@mui/material";
import { AddCircle, SearchSharp } from "@mui/icons-material";
import { IconButton } from "../../controls";
import PaperService from "../../services/paper";
import { styled, alpha } from "@mui/material/styles";
import PaperCard from "../../components/PaperCard";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function Home() {
  const navigate = useNavigate();
  const { setIsLoading, setErrorMessage } = useStore((state) => state);
  const { token } = usePersistStore((state) => state);
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState([]);

  const reload = () => {
    setIsLoading(true);
    PaperService.list(token, { keyword }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      setList(result?.data);
    });
  };

  useEffect(() => {
    reload();
  }, [keyword]);

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            component="div"
            variant="h4"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            Papers
            <IconButton icon={<AddCircle />} onClick={() => navigate("/new")} />
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <Box sx={{ width: "auto", mt: 1 }}>
          <Search>
            <SearchIconWrapper>
              <SearchSharp sx={{ color: "grey" }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{
                "aria-label": "search",
                autoFocus: false,
                "aria-autocomplete": "false",
              }}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </Search>
        </Box>
      </Grid>
      {list?.length === 0 && (
        <Grid item xs={12}>
          <Typography>You have not created any papers yet.</Typography>
        </Grid>
      )}
      {list?.map((k) => (
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12} key={k?._id}>
          <PaperCard data={k} reload={reload} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Home;
