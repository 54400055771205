import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Button,
  Menu,
  Tooltip,
  Avatar,
  MenuItem,
} from "@mui/material";
import { AutoGraphTwoTone } from "@mui/icons-material";
import { usePersistStore } from "../store";
import Confirm from "./Confirm";

const LinkBehavior = React.forwardRef((props, ref) => (
  <Link ref={ref} to={props.href} {...props} />
));

const Logo = () => (
  <>
    <AutoGraphTwoTone
      fontSize="large"
      sx={{ mr: 2, cursor: "pointer", color: "lime" }}
    />
    <Typography
      variant="h5"
      noWrap
      component={LinkBehavior}
      href="/"
      sx={{
        mr: 2,
        fontWeight: 600,
        color: "inherit",
        textDecoration: "none",
      }}
    >
      {process.env.REACT_APP_NAME}
    </Typography>
  </>
);

function Header() {
  const navigate = useNavigate();
  const { user, logout } = usePersistStore((state) => state);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleLogout = () => {
    logout();
    window.location.href = "/login";
  };

  return (
    <>
      <Confirm
        open={openConfirmDialog}
        onNo={() => setOpenConfirmDialog(false)}
        onYes={() => {
          setOpenConfirmDialog(false);
          handleLogout();
        }}
        title="Want to logout?"
        body="You'll be logged out from the application."
      />
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Logo />
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Button
                key="profile"
                onClick={() => navigate("/profile")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Profile
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user.name} src="not-found.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  key="logout"
                  onClick={() => {
                    setOpenConfirmDialog(true);
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;
