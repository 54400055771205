import React, { useState, useEffect } from "react";
import UserService from "../../services/user";
import Utils from "../../utils/utils";
import { Error, Success, Heading, LinkButton } from "../../controls";
import { Container } from "@mui/material";

function Activate(props) {
  const [isActive, setIsActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const token = Utils.getUrlParam(window.location.href, "token", "");
    if (!token || token.length !== 24) {
      setErrorMessage(`Invalid token provided.`);
      return;
    }

    UserService.activateAccount(token).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        return;
      }

      setErrorMessage("");
      setIsActive(true);
    });
  }, []);

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Heading text="Activate your account" sx={{ mt: 10, mb: 3 }} />
      <LinkButton label="Want to login?" text="Sign In" to="/login" />
      <Error text={errorMessage} />
      {isActive && (
        <Success
          text={
            "Your account is activated successfully! Please login and start using the application."
          }
        />
      )}
    </Container>
  );
}

export default Activate;
