import validator from "validator";

export default class Utils {
  static isValidEmail = (email) => validator.isEmail(email);

  static formatToNumber = (amount) => {
    if (!amount) return;
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      .replace(".00", "");
  };

  static replaceAll = (oldString, newString, fullString) =>
    fullString.split(oldString).join(newString);
}
