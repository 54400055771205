import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const storeName = `@${process.env.REACT_APP_NAME?.replaceAll(
  " ",
  "_"
).toUpperCase()}`;

const usePersistStore = create(
  persist(
    (set) => ({
      token: "",
      setToken: (t) => set({ token: t }),
      user: null,
      setUser: (t) => set({ user: t }),
      emailAddress: "",
      setEmailAddress: (t) => set({ emailAddress: t }),
      verificationPurpose: "",
      setVerificationPurpose: (t) => set({ verificationPurpose: t }),
      logout: () =>
        set({
          isLoggedIn: false,
          token: "",
          user: null,
          emailAddress: "",
        }),
    }),
    {
      name: storeName,
      storage: createJSONStorage(() => localStorage),
    }
  )
);

const useStore = create((set) => ({
  isLoading: false,
  setIsLoading: (t) => set({ isLoading: t }),
  isUploading: false,
  setIsUploading: (t) => set({ isUploading: t }),
  successMessage: "",
  setSuccessMessage: (t) => set({ successMessage: t }),
  errorMessage: "",
  setErrorMessage: (t) => set({ errorMessage: t }),
  infoMessage: "",
  setInfoMessage: (t) => set({ infoMessage: t }),
}));

export { useStore, usePersistStore };
