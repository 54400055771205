import React, { useState } from "react";
import { useStore, usePersistStore } from "../../store";
import { Grid, Typography, Box, Paper } from "@mui/material";
import { Edit, School, Key } from "@mui/icons-material";
import moment from "moment";
import { Button, IconButton, Password } from "../../controls";
import UserService from "../../services/user";

function Profile() {
  const { setIsLoading, setErrorMessage, setSuccessMessage } = useStore(
    (state) => state
  );
  const { token, user, setUser } = usePersistStore((state) => state);
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const handleUpdate = () => {
    let name = prompt("Name");
    if (name) {
      UserService.updateName(token, { name }).then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        const data = result.data;
        setErrorMessage("");
        setSuccessMessage("Name updated successfully!");
        setIsLoading(false);
        setUser(data);
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!newPassword || !repeatPassword) {
      setErrorMessage(`Password is required.`);
      return;
    }

    if (newPassword !== repeatPassword) {
      setErrorMessage(`Password and Repeat password does not match.`);
      return;
    }

    setIsLoading(true);
    UserService.resetPassword({
      email: user?.email,
      password: newPassword,
    }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      setSuccessMessage(
        `Password updated successfully! Use new password to login next time.`
      );
      setNewPassword("");
      setRepeatPassword("");
    });
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} md={6} lg={4} xl={4}>
        <Box component={Paper} sx={{ p: 4, borderRadius: 4 }} elevation={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography component="p" variant="h5">
                Profile
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography component="p">{user?.name}</Typography>
              <Typography component="p">{user?.email}</Typography>
              <Typography component="p">
                Member since: {moment(user.createdAt).format("DD MMMM YYYY")}
              </Typography>
              <Typography
                component="div"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                Role: Teacher <School />
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton icon={<Edit />} onClick={handleUpdate} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={4}>
        <Box component={Paper} sx={{ p: 4, borderRadius: 4 }} elevation={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography component="p" variant="h5">
                Update password
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Password
                label="New password"
                value={newPassword}
                onChange={setNewPassword}
              />
              <Password
                label="Repeat password"
                value={repeatPassword}
                onChange={setRepeatPassword}
              />
              <Button
                text="Reset password"
                onClick={handleSubmit}
                sx={{ mt: 2 }}
                icon={<Key />}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={0} md={0} lg={4} xl={4}></Grid>
    </Grid>
  );
}

export default Profile;
