import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Text, Button, Error, Success } from "../../controls";
import { useStore, usePersistStore } from "../../store";
import PaperService from "../../services/paper";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../controls";
import { KeyboardBackspace } from "@mui/icons-material";

function Create() {
  const navigate = useNavigate();
  const { setIsLoading } = useStore((state) => state);
  const { token } = usePersistStore((state) => state);
  let [title, setTitle] = useState("");
  let [text, setText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleCreate = () => {
    if (!title) {
      setErrorMessage("Title is required.");
      return;
    }
    if (!text) {
      setErrorMessage("Prompt text is required.");
      return;
    }

    setIsLoading(true);
    PaperService.create(token, { title, description: text }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      setSuccessMessage(`Paper created successfully!`);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    });
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Typography
          component="div"
          variant="h4"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <IconButton
            icon={<KeyboardBackspace />}
            onClick={() => navigate("/")}
          />
          Create
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Text
          label="Title"
          placeholder="Paper title"
          sx={{ maxWidth: 400 }}
          value={title}
          onChange={setTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <Text
          label="Prompt"
          placeholder="Write prompt to train the GPT model..."
          multiline
          rows={15}
          value={text}
          onChange={setText}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          text="Create"
          onClick={handleCreate}
          value={text}
          onChange={setText}
          sx={{ maxWidth: 100 }}
        />
        <Error text={errorMessage} />
        <Success text={successMessage} />
      </Grid>
    </Grid>
  );
}

export default Create;
