import React, { useState } from "react";
import UserService from "../../services/user";
import { useStore, usePersistStore } from "../../store";
import {
  Button,
  Password,
  Error,
  Success,
  Heading,
  LinkButton,
} from "../../controls";
import { Key } from "@mui/icons-material";
import { Container } from "@mui/material";

function ResetPassword() {
  const { emailAddress, setEmailAddress } = usePersistStore((state) => state);
  const { setIsLoading } = useStore((state) => state);
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!newPassword || !repeatPassword) {
      setErrorMessage(`Please provide all fields.`);
      return;
    }

    if (newPassword !== repeatPassword) {
      setErrorMessage(`Password and Repeat password does not match.`);
      return;
    }

    setIsLoading(true);
    UserService.resetPassword({
      email: emailAddress,
      password: newPassword,
    }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      setEmailAddress("");
      setSuccessMessage(
        `Password updated successfully! Use new password to login.`
      );
    });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Heading text="Create new password" sx={{ mt: 10, mb: 3 }} />
      <Password
        label="New Password"
        value={newPassword}
        onChange={setNewPassword}
      />
      <Password
        label="Repeat Password"
        value={repeatPassword}
        onChange={setRepeatPassword}
      />
      <Button
        text="Reset Password"
        onClick={handleSubmit}
        sx={{ mt: 2, mb: 2 }}
        icon={<Key />}
      />
      <LinkButton label="Want to try login?" text="Sign In" to="/login" />
      <LinkButton label="Don't have an account?" text="Sign Up" to="/signup" />
      <Error text={errorMessage} />
      <Success text={successMessage} />
    </Container>
  );
}

export default ResetPassword;
