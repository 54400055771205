import React, { useState } from "react";
import { Typography, Box, Paper, Tooltip } from "@mui/material";
import { Delete, Launch, Share } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../controls";
import moment from "moment";
import Confirm from "./Confirm";
import PaperService from "../services/paper";
import { usePersistStore, useStore } from "../store";

function PaperCard({ data, reload }) {
  const navigate = useNavigate();
  const { token } = usePersistStore((state) => state);
  const { setErrorMessage, setSuccessMessage, setIsLoading } = useStore(
    (state) => state
  );
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PUBLIC_URL}/submit/${data?._id}`
    );
    setSuccessMessage("Paper URL is copied to clipboard.");
  };

  const handleDelete = () => {
    if (!data || !data?._id || !token) return;
    setIsLoading(true);
    PaperService.deleteById(token, data?._id).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      reload();
    });
  };

  return (
    <>
      <Confirm
        open={openConfirmDialog}
        onNo={() => setOpenConfirmDialog(false)}
        onYes={() => {
          setOpenConfirmDialog(false);
          handleDelete();
        }}
        title="Delete"
        body="Are you sure you want to delete this paper? Paper information and all its submissions will be deleted. This action cannot be undone."
      />
      <Box
        component={Paper}
        sx={{ p: 2, borderRadius: 4, width: "auto" }}
        elevation={3}
      >
        <Typography noWrap component="p">
          {data?.title}
        </Typography>
        <Typography noWrap color="grey" sx={{ fontSize: 12 }} component="p">
          {moment(data?.createdAt).format("DD MMM YYYY")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography noWrap color="grey" sx={{ fontSize: 12 }} component="p">
            {data?.submissions} Submissions
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Tooltip title="Delete this paper">
              <div>
                <IconButton
                  icon={<Delete fontSize="small" />}
                  onClick={() => setOpenConfirmDialog(true)}
                />
              </div>
            </Tooltip>
            <Tooltip title="Copy paper URL to share">
              <div>
                <IconButton
                  icon={<Share fontSize="small" />}
                  onClick={handleCopy}
                />
              </div>
            </Tooltip>
            <Tooltip title="Open and check submissions">
              <div>
                <IconButton
                  icon={<Launch fontSize="small" />}
                  onClick={() => navigate(`/paper/${data?._id}`)}
                />
              </div>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default PaperCard;
