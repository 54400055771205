import React, { useState, useEffect } from "react";
import { Typography, Box, Paper } from "@mui/material";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

function SubmissionCard({ data }) {
  let [correct, setCorrect] = useState(0);
  let [total, setTotal] = useState(0);
  useEffect(() => {
    if (data?.summary) {
      let summary = JSON.parse(data?.summary) || [];
      let correctAnswers = summary.filter((k) => k?.is_right === true);
      setCorrect(correctAnswers?.length);
      setTotal(summary?.length);
    }
  }, [data]);

  return (
    <Box component={Paper} sx={{ p: 2, borderRadius: 4 }} elevation={3}>
      <Typography noWrap component="p">
        {data?.registrationNumber}
        <Typography
          noWrap
          color="grey"
          sx={{ fontSize: 12, ml: 1 }}
          component="span"
        >
          {moment(data?.createdAt).format("DD MMM YYYY")}
        </Typography>
      </Typography>
      <Typography noWrap component="p" sx={{ my: 1 }}>
        Correct answers: {correct}/{total}
      </Typography>
      <Swiper
        spaceBetween={20}
        navigation={true}
        pagination={true}
        modules={[Navigation, Pagination]}
        slidesPerView={2}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {data?.files?.map((k) => (
          <SwiperSlide>
            <img
              src={k}
              alt=""
              style={{
                borderRadius: 10,
                overflow: "hidden",
                border: "1px solid #eee",
                display: "block",
                width: 300,
                height: 400,
                objectFit: "cover",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default SubmissionCard;
