import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Button } from "../../controls";
import { useStore, usePersistStore } from "../../store";
import PaperService from "../../services/paper";
import SubmissionService from "../../services/submission";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "../../controls";
import { Checklist, KeyboardBackspace } from "@mui/icons-material";
import SubmissionCard from "../../components/SubmissionCard";

function Details() {
  const navigate = useNavigate();
  let { paperId } = useParams();
  const { setIsLoading, setErrorMessage, setSuccessMessage } = useStore(
    (state) => state
  );
  const { token } = usePersistStore((state) => state);
  const [paper, setPaper] = useState(null);
  const [submissions, setSubmissions] = useState(null);

  useEffect(() => {
    if (!paperId) return;
    reload();
  }, [paperId]);

  const reload = () => {
    setIsLoading(true);
    PaperService.getDetailsById(token, { paperId }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      setPaper(result.data?.paper);
      setSubmissions(result.data?.submissions);
    });
  };

  const handleGenerateSummary = () => {
    setIsLoading(true);
    SubmissionService.getSummary(token, { paperId }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setErrorMessage("");
      setSuccessMessage("Summary is generated successfully!");
      reload();
    });
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={9}>
        <Typography
          component="div"
          variant="h4"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <IconButton
            icon={<KeyboardBackspace />}
            onClick={() => navigate("/")}
          />
          Details
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button
          text="Generate Summary"
          width={250}
          icon={<Checklist />}
          onClick={handleGenerateSummary}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="p" variant="h6">
          {paper?.title}
        </Typography>
        <Typography component="p" variant="p" sx={{ mt: 1 }}>
          {paper?.description}
        </Typography>
        <Typography component="p" variant="p" sx={{ mt: 1 }}>
          <strong>Summary</strong>
        </Typography>
        <Typography component="p" variant="p" sx={{ mt: 1 }}>
          {paper?.summary}
        </Typography>
        <Typography component="p" variant="p" sx={{ mt: 1 }}>
          <strong>Submissions:</strong> {submissions?.length} submissions
        </Typography>
      </Grid>
      {submissions?.map((k) => (
        <Grid item xs={6} key={k?._id}>
          <SubmissionCard data={k} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Details;
