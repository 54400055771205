import React, { useState } from "react";
import UserService from "../../services/user";
import Utils from "../../utils/utils";
import { useStore, usePersistStore } from "../../store";
import {
  Button,
  Text,
  Error,
  Success,
  Heading,
  LinkButton,
} from "../../controls";
import { PasswordOutlined } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ForgetPassword() {
  const navigate = useNavigate();
  const { setIsLoading } = useStore((state) => state);
  const { setEmailAddress, setVerificationPurpose } = usePersistStore(
    (state) => state
  );
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || email.length === 0) {
      setErrorMessage(`Email is required.`);
      return;
    } else {
      if (!Utils.isValidEmail(email)) {
        setErrorMessage(`Please provide a valid email address.`);
        return;
      }
    }

    setIsLoading(true);
    UserService.forgotPassword(email).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setEmailAddress(email);
      setIsLoading(false);
      setErrorMessage("");
      setVerificationPurpose("rp");
      setSuccessMessage(`Email sent containing password reset information.`);
      setTimeout(() => {
        navigate("/verify");
      }, 1000);
    });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Heading text="Forgot your password?" sx={{ mt: 10, mb: 3 }} />
      <Text label="Email address" value={email} onChange={setEmail} />
      <Button
        text="Send OTP"
        onClick={handleSubmit}
        sx={{ mt: 2, mb: 2 }}
        icon={<PasswordOutlined />}
      />
      <LinkButton label="Want to try login?" text="Sign In" to="/login" />
      <LinkButton label="Create new account?" text="Sign Up" to="/signup" />
      <Error text={errorMessage} />
      <Success text={successMessage} />
    </Container>
  );
}

export default ForgetPassword;
