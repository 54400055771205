import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import imgTick from "../../media/tick.gif";

function SubmitSuccess() {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            mt: 10,
          }}
        >
          <Typography component="div" variant="h4">
            Your paper is submitted successfully!
          </Typography>
          <img src={imgTick} alt="" />
        </Box>
      </Grid>
    </Grid>
  );
}

export default SubmitSuccess;
