import React, { useState } from "react";
import UserService from "../../services/user";
import Utils from "../../utils/utils";
import { useStore, usePersistStore } from "../../store";
import {
  Button,
  Text,
  Password,
  Error,
  Success,
  Heading,
  LinkButton,
} from "../../controls";
import { PersonAdd } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Signup(props) {
  const navigate = useNavigate();
  const { setIsLoading } = useStore((state) => state);
  const { setVerificationPurpose, setEmailAddress } = usePersistStore(
    (state) => state
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessage("");
    setSuccessMessage("");

    if (!email || !password || !name) {
      setErrorMessage(`All fields are required.`);
      return;
    }
    if (!Utils.isValidEmail(email)) {
      setErrorMessage(`Please provide a valid email address.`);
      return;
    }

    setIsLoading(true);
    UserService.signup({
      name,
      email,
      password,
    }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setErrorMessage("");
      setSuccessMessage(
        `Signup successful! Please check your mailbox to activate your account.`
      );
      setIsLoading(false);
      setEmailAddress(email);
      setVerificationPurpose("signup");
      setTimeout(() => {
        navigate("/verify");
      }, 1000);
    });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Heading text="Sign Up" sx={{ mt: 10, mb: 3 }} />
      <Text label="Full name" value={name} onChange={setName} />
      <Text label="Email address" value={email} onChange={setEmail} />
      <Password label="Password" value={password} onChange={setPassword} />
      <Button
        text="Create Account"
        onClick={handleSubmit}
        sx={{ mt: 2, mb: 2 }}
        icon={<PersonAdd />}
      />
      <LinkButton label="Already have account?" text="Sign In" to="/login" />
      <Error text={errorMessage} />
      <Success text={successMessage} />
    </Container>
  );
}

export default Signup;
